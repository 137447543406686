import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useMutation } from "@tanstack/react-query";
import { addEducationInformation } from "../../hooks/useEmployeeMoreInfo";

const schema = yup.object().shape({
  educationInfo: yup.array().of(
    yup.object().shape({
      institution: yup.string().required("Institution is required"),
      startDate: yup.string().nullable().required("Starting Date is required"),
      completeDate: yup
        .string()
        .nullable()
        .required("Complete Date is required"),
      degree: yup.string().required("Degree is required"),
      grade: yup.string().required("Grade is required"),
    })
  ),
});

const PersonalInfoEducation = ({ refetch, employee_education }) => {
  const { mutate: addEducationInformationMutation } = useMutation({
    mutationFn: addEducationInformation,
    onSuccess: () => {
      reset();
      refetch();
    },
  });

  const addEducation = (data) => {
    addEducationInformationMutation(data);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "educationInfo",
  });

  useEffect(() => {
    const defaultValues = {
      educationInfo:
        employee_education?.length > 0
          ? employee_education.map((item) => ({
              institution: item?.institute || "",
              startDate: item?.start_date || null,
              completeDate: item?.end_date || null,
              degree: item?.degree || "",
              grade: item?.grade || "",
            }))
          : [
              {
                institution: "",
                startDate: null,
                completeDate: null,
                degree: "",
                grade: "",
              },
            ],
    };

    reset(defaultValues);
  }, [employee_education, reset]);

  const onSubmit = (data) => {
    const payload = data.educationInfo.map((item) => ({
      employee_id: id,
      institute: item.institution,
      degree: item.degree,
      grade: item.grade,
      start_date: dayjs(item.startDate).format("YYYY"),
      end_date: dayjs(item.completeDate).format("YYYY"),
    }));
    addEducation(payload);
  };

  return (
    <div id="education_info" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Education Information</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-scroll">
                {fields.map((field, index) => (
                  <div className="card mb-3" key={field.id}>
                    <div className="card-body">
                      <h3 className="card-title">
                        Education Information
                        {index > 0 && (
                          <div
                            onClick={() => remove(index)}
                            className="delete-icon"
                            role="button"
                          >
                            <i className="fa-regular fa-trash-can" />
                          </div>
                        )}
                      </h3>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.educationInfo?.[index]?.institution
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`educationInfo[${index}].institution`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control floating"
                                />
                              )}
                            />
                            <label className="focus-label">
                              Institution <span className="text-danger">*</span>
                            </label>
                            {errors.educationInfo?.[index]?.institution && (
                              <p
                                className="text-danger mt-1"
                                style={{
                                  marginTop: "0.25rem",
                                  marginBottom: "0",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {
                                  errors.educationInfo[index].institution
                                    .message
                                }
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.educationInfo?.[index]?.startDate
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`educationInfo[${index}].startDate`}
                              control={control}
                              render={({ field }) => (
                                <DatePicker
                                  picker="year"
                                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                  value={
                                    field.value ? dayjs(field.value) : null
                                  }
                                  onChange={(date) =>
                                    field.onChange(date ? date.toDate() : null)
                                  }
                                  className="form-control floating datetimepicker"
                                />
                              )}
                            />
                            <label className="focus-label">
                              Starting Year{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {errors.educationInfo?.[index]?.startDate && (
                              <p
                                className="text-danger mt-1"
                                style={{
                                  marginTop: "0.25rem",
                                  marginBottom: "0",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {errors.educationInfo[index].startDate.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.educationInfo?.[index]?.completeDate
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`educationInfo[${index}].completeDate`}
                              control={control}
                              render={({ field }) => (
                                <DatePicker
                                  picker="year"
                                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                  value={
                                    field.value ? dayjs(field.value) : null
                                  }
                                  onChange={(date) =>
                                    field.onChange(date ? date.toDate() : null)
                                  }
                                  className="form-control floating datetimepicker"
                                />
                              )}
                            />
                            <label className="focus-label">
                              Complete Year{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {errors.educationInfo?.[index]?.completeDate && (
                              <p
                                className="text-danger mt-1"
                                style={{
                                  marginTop: "0.25rem",
                                  marginBottom: "0",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {
                                  errors.educationInfo[index].completeDate
                                    .message
                                }
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.educationInfo?.[index]?.degree
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`educationInfo[${index}].degree`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control floating"
                                />
                              )}
                            />
                            <label className="focus-label">
                              Degree <span className="text-danger">*</span>
                            </label>
                            {errors.educationInfo?.[index]?.degree && (
                              <p
                                className="text-danger mt-1"
                                style={{
                                  marginTop: "0.25rem",
                                  marginBottom: "0",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {errors.educationInfo[index].degree.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.educationInfo?.[index]?.grade
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`educationInfo[${index}].grade`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control floating"
                                />
                              )}
                            />
                            <label className="focus-label">
                              Grade <span className="text-danger">*</span>
                            </label>
                            {errors.educationInfo?.[index]?.grade && (
                              <p
                                className="text-danger mt-1"
                                style={{
                                  marginTop: "0.25rem",
                                  marginBottom: "0",
                                  fontSize: "0.875rem",
                                }}
                              >
                                {errors.educationInfo[index].grade.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="add-more"
                  style={{ color: "#FF902F" }}
                  role="button"
                >
                  <div onClick={() => append({})}>
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoEducation;
