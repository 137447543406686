import React, { useState, useEffect } from "react";
import Select from "react-select";

const EditUserModal = ({ selectedId, updateUser, selectedUserData,roleData }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionTwo, setSelectedOptionTwo] = useState(null);  
  const [roles, setRoles] = useState(null);  
  
  useEffect(() => {
    const data = roleData?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setRoles(data);
  }, [roleData]);
  
  
  const onEyeClick = () => setEye(prevEye => !prevEye);
  
  const [eye, setEye] = useState(true);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    role: "",
    company: "",
    employeeId: "",
  });

  useEffect(() => {
    if (selectedUserData) {
      // console.log(roles, "wowo")
      setFormData({
        id: selectedId,
        firstname: selectedUserData.firstname || "",
        lastname: selectedUserData.lastname || "",
        username: selectedUserData.username || "",
        email: selectedUserData.email || "",
        password: "",
        password_confirmation: "",
        phone: selectedUserData.phone || "",
        role: selectedUserData.role || "",
        company: selectedUserData.company || "",
        employeeId: selectedUserData.employeeId || "",
      });
      setSelectedOption(roles?.find(option => option.label === selectedUserData.role));
      setSelectedOptionTwo(optionsTwo?.find(option => option.label === selectedUserData.company));
    }
  }, [selectedUserData, selectedId]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const optionsTwo = [
    { value: "1", label: "Xpert Prime" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = { ...formData, id: selectedId };
    updateUser(userData);
  };

  return (
    <div id="edit_user" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit User</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Employee ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control floating"
                      name="employeeId"
                      value={formData.employeeId}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3" style={{ position: "relative" }}>
                    <label className="col-form-label">Password</label>
                    <input
                      className="form-control"
                      type={eye ? "password" : "text"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                     <span
                      onClick={onEyeClick}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "60%", // Center the icon vertically
                        // transform: "translateY(-100%)", // Adjust for perfect centering
                        cursor: "pointer",
                      }}
                      className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    />

                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3" style={{ position: "relative" }}>
                    <label className="col-form-label">Confirm Password</label>
                    <input
                      className="form-control"
                      type={eye ? "password" : "text"}
                      name="password_confirmation"
                      value={formData.password_confirmation}
                      onChange={handleChange}
                    />
                     <span
                      onClick={onEyeClick}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "60%", // Center the icon vertically
                        // transform: "translateY(-100%)", // Adjust for perfect centering
                        cursor: "pointer",
                      }}
                      className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Phone </label>
                    <input
                      className="form-control"
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Role</label>
                    <Select
                      placeholder="Select Role"
                      value={selectedOption}
                      onChange={(option) => {
                        setSelectedOption(option);
                        setFormData(prevState => ({
                          ...prevState,
                          role: option.value,
                        }));
                      }}
                      options={roles}
                      className="select floating"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Company</label>
                    <Select
                      placeholder="Select Company"
                      value={selectedOptionTwo}
                      onChange={(option) => {
                        setSelectedOptionTwo(option);
                        setFormData(prevState => ({
                          ...prevState,
                          company: option.value,
                        }));
                      }}
                      options={optionsTwo}
                      className="select floating"
                      styles={customStyles}
                    />
                  </div>
                </div>
                
              </div>

              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
