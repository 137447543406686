import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is loaded

// Define validation schema with Yup
const schema = yup.object().shape({
  shift_name: yup.string().required("Shift name is required"),
  start_time: yup.string().required("Shift start time is required"),
  end_time: yup.string().required("Shift end time is required"),
});

const AddShiftModal = ({ addShift }) => {
  // Initialize react-hook-form with schema validation
  const { handleSubmit, control, formState: { errors, isValid }, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange" // This enables real-time validation for the form
  });

  // Handler function for form submission
  const onSubmitHandler = (data) => {
    addShift(data); // Add shift with provided data
    reset({
      shift_name: "",
      start_time: "",
      end_time: ""
    }); // Reset form fields after submission
  };

  return (
    <>
      <div
        id="addShiftModal"
        className="modal custom-modal fade"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Shift</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Shift Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="shift_name"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${errors.shift_name ? "is-invalid" : ""}`}
                        type="text"
                      />
                    )}
                  />
                  {errors.shift_name && (
                    <div className="invalid-feedback">
                      {errors.shift_name.message}
                    </div>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Shift Start <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="start_time"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${errors.start_time ? "is-invalid" : ""}`}
                        type="time"
                      />
                    )}
                  />
                  {errors.start_time && (
                    <div className="invalid-feedback">
                      {errors.start_time.message}
                    </div>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Shift End <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="end_time"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${errors.end_time ? "is-invalid" : ""}`}
                        type="time"
                      />
                    )}
                  />
                  {errors.end_time && (
                    <div className="invalid-feedback">
                      {errors.end_time.message}
                    </div>
                  )}
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={!isValid} // Disable the button if the form is not valid
                    data-bs-dismiss={isValid ? "modal" : undefined} // Only dismiss modal if the form is valid
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShiftModal;
