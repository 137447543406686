/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Popover } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { AdminLeaveAddModelPopup } from "../../../components/modelpopup/AdminLeaveModelPopup";
import SearchBox from "../../../components/SearchBox";
import {
  addEmployeeLeave,
  deleteEmployeeLeave,
  fetchAllEmployeesLeave,
  updateEmployeeLeave,
  updateEmployeeLeaveStatus,
} from "../../../hooks/useEmployeeLeave";
import { useMutation, useQuery } from "@tanstack/react-query";
import InfoToast from "../../layout/InfoToast";
import Spinnerloaders from "../../../components/SpinnerLoader";
import { formatDate } from "../../../assets/scss/utils/helpers/helper_functions";

const AdminLeave = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const { data, error, status, refetch } = useQuery({
    queryKey: ["allEmployeesLeave"],
    queryFn: fetchAllEmployeesLeave,
    refetchOnWindowFocus: false,
  });

  const [selectedEmployee, setSelectedEmployee] = useState({});

  const { mutate: addEmployeeMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addEmployeeLeave,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Leave Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: error.response?.data?.data || 'An error occurred',
        type: "Error",
      });
    },
  });

  const { mutate: updateEmployeeMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeeLeave,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Leave Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: error.response?.data?.data || 'An error occurred',
        type: "Error",
      });
    },
  });

  const { mutate: deleteEmployeeMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteEmployeeLeave,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Leave Deleted", type: "Deleted" });
    },
    onError: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Leave",
        type: "Error",
      });
    },
  });

  const { mutate: updateLeaveStatusMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeeLeaveStatus,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Leave Updated", type: "Updated" });
    },
    onError: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Leave",
        type: "Error",
      });
    },
  });

  const updateLeaveStatus = (item, status) => {
    const data = {
      id: item.id,
      status,
      leave_type: item.leave_type_id,
      employee: item.employee_id,
      reason: item.reason,
      from: item.from,
      to: item.to,
    };
    updateLeaveStatusMutation(data);
  };

  const addEmployeeData = (data) => {
    addEmployeeMutation(data);
  };

  const updateEmployeeData = (data) => {
    updateEmployeeMutation(data);
  };
  // const handleTableChange = (pagination) => {
  //   setCurrentPage(pagination.current);
  // };
  const deleteEmployeeData = (data) => {
    deleteEmployeeMutation(data);
  };

  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {((pagination.current - 1) * pagination.pageSize) + index + 1}
        </span>
      ),
    },
    {
      title: "Employee Name",
      render: (_, record) => (
        <span className="table-avatar">
          {record?.employee?.firstname +
            " " +
            (record?.employee?.lastname || " ")}
        </span>
      ),
    },
    {
      title: "Leave Type",
      render: (_, record) => <span>{record.leave_type?.type}</span>,
    },
    {
      title: "From",
      dataIndex: "from",
      render: (text) => <span>{formatDate(text)}</span>,
      sorter: (a, b) => {
        const dateA = new Date(a.from);
        const dateB = new Date(b.from);
        return dateB - dateA; // Ascending order
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: "To",
      dataIndex: "to",
      render: (text) => <span>{formatDate(text)}</span>,
      sorter: (a, b) => new Date(a.to) - new Date(b.to),
    },
    {
      title: "No Of Days",
      render: (_, record) => <span>{record?.no_of_days}</span>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Leave Reason"
          trigger="hover"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-left">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateLeaveStatus(record, "Pending")}
            >
              <i className="far fa-dot-circle text-info" /> Pending
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateLeaveStatus(record, "Approved")}
            >
              <i className="far fa-dot-circle text-success" /> Approved
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateLeaveStatus(record, "Declined")}
            >
              <i className="far fa-dot-circle text-danger" /> Declined
            </Link>
          </div>
        </div>
      ),
      
    },
    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_leave"
              onClick={() => {
                setSelectedEmployee(record);
                setEditable(true);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedEmployee(record);
              }}
            >
              <i className="fa-regular fa-trash-can m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div
            onClick={() => {
              setEditable(false);
            }}
          >
            <Breadcrumbs
              maintitle="Leaves"
              title="Dashboard"
              subtitle="Leaves"
              modal="#add_leave"
              name="Add Leave"
            />
          </div>
          {/* <div className="row">
            {statsData.map((stat, index) => (
              <div className="col-md-3 d-flex" key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <h4>
                      {stat.value} <span>{stat.subText}</span>
                    </h4>
                  ) : (
                    <h4>{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          {/* <LeaveFilter leaveTypes={data?.leave_types} /> */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={data?.leaves}
                  style={{ overflowX: "auto" }}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
                {isRefetching && <Spinnerloaders />}
              </div>
            </div>
          </div>
        </div>
        <AdminLeaveAddModelPopup
          selectedEmployee={selectedEmployee}
          addEmployeeData={addEmployeeData}
          updateEmployeeData={updateEmployeeData}
          editable={editable}
          leaveTypes={data?.leave_types}
          employees={data?.employees}
        />
        <DeleteModal
          id={selectedEmployee.id}
          deleteDepartment={deleteEmployeeData}
          Name="Delete Leaves"
        />
        {success && (
          <InfoToast
            success={success}
            handleClose={handleSolidPrimaryToastClose}
            toastType={toastType}
          />
        )}
      </div>
    </>
  );
};

export default AdminLeave;
