import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import AddDesingnationModelPopup from "../../../components/modelpopup/AddDesingnationModelPopup";
import SearchBox from "../../../components/SearchBox";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchDesignations,
  addDesignations,
  updateDesignations,
  deleteDesignations,
} from "../../../hooks/useDesignation";
import InfoToast from "../../layout/InfoToast";
import Spinnerloaders from "../../../components/SpinnerLoader";

const Designation = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState([
    {
      id: null,
      designation: "",
      department: [],
    },
  ]);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };
  // ===========================================Get Designations==============================
  const { data, error, status, refetch } = useQuery({
    queryKey: ["designations"],
    queryFn: fetchDesignations,
    refetchOnWindowFocus: false,
  });

  // ===========================================Add Designations==============================

  const { mutate: addDes } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addDesignations,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Designation Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Adding Designation",
        type: "Error",
      });
    },
  });
  const addDesignation = (data) => {
    addDes(data);
  };
  // ===========================================Update Designations==============================

  const { mutate: updDesMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateDesignations,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Designation Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Designation",
        type: "Error",
      });
    },
  });
  const updateDesignation = (data) => {
    updDesMutation(data);
  };
  // ===========================================Deleted Designations==============================

  const { mutate: delDesMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteDesignations,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Designation Deleted", type: "Deleted" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Designation",
        type: "Error",
      });
    },
  });
  const deleteDesignation = (data) => {
    delDesMutation(data);
  };

  // =========================================End Queries===================================

  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }
  const userElements = data?.designations?.map((user, index) => ({
    key: index,
    id: user.id,
    designation: user?.name,
    department: user?.department?.name,
    departmentId: user?.department_id,
  }));

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {((pagination.current - 1) * pagination.pageSize) + index + 1}
        </span>
      ),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.length - b.designation.length,
      width: "40%",
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
      width: "40%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_designation"
              onClick={() => {
                setSelectedDesignation(() => ({
                  id: record.id,
                  designation: record.designation,
                  department: {
                    label: record.department,
                    value: record.departmentId,
                  },
                }));
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedDesignation(() => ({
                  id: record.id,
                  designation: record.designation,
                  department: {
                    label: record.department,
                    value: record.departmentId,
                  },
                }));
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Designations"
            title="Dashboard"
            subtitle="Designations"
            modal="#add_designation"
            name="Add Designation"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
                {isRefetching && <Spinnerloaders />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddDesingnationModelPopup
        selectedDesignation={selectedDesignation}
        departments={data?.department}
        addDesignation={addDesignation}
        updateDesignation={updateDesignation}
      />
      <DeleteModal
        Name="Delete Designation"
        deleteDepartment={() => deleteDesignation(selectedDesignation.id)}
      />
      <InfoToast
        success={success}
        handleClose={handleSolidPrimaryToastClose}
        toastType={toastType}
      />
    </div>
  );
};

export default Designation;
