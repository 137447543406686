import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    email: "",
    password: "",
    header: false,
    profile: false,
    isAuthenticated: !!localStorage.getItem('token'),
    role: "", 
    permissions: [],
  },
  reducers: {
    login: (state, { payload }) => {
      console.log(payload, "this is users data on login");
      state.email = payload.email;
      state.password = payload.password;
      state.role = payload.role;
      state.permissions = payload.permission;
      state.isAuthenticated = true; // Set to true on login
    },
    logout: (state) => {
      state.email = "";
      state.password = "";
      state.isAuthenticated = false; // Set to false on logout
      state.role = "";
      state.permissions = []; 
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
    setToggleHeader: (state, { payload }) => {
      state.header = payload;
    },
    setProfileData: (state, { payload }) => {
      state.profile = payload;
    },
  },
});

export const { login, logout, setLayout, setToggleHeader, setProfileData } = userSlice.actions;
export default userSlice.reducer;
