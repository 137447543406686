import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { headerlogo } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import { resetFunctionwithlogin } from "../../../components/ResetFunction";
import { useMutation } from "@tanstack/react-query";
import useAuth, { loginRequest } from "../../../hooks/useAuth";
import InfoToast from "../../layout/InfoToast";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = () => {
  const { register, control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const watchEmail = watch("email");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState('');

  const mutation = useMutation({
    mutationFn: loginRequest,
    onSuccess: (data) => {
      localStorage.setItem('userData', JSON.stringify(data));
      localStorage.setItem('token', data.token);
      localStorage.setItem('role', data.role);
      dispatch(login({ email: data.email, password: data.password, role: data.role, permission: data.permissions }));
      navigate("/employee-dashboard");
    },
    onError: (error) => {
      setToastType({ label: 'Incorrect username or password', type: 'Error' });
      setSuccess(true);
    },
  });

  useEffect(() => {
    setValue("email", localStorage.getItem("email") || "");
    setValue("password", localStorage.getItem("password") || "");
  }, [setValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const onEyeClick = () => setEye(prevEye => !prevEye);

  const [eye, setEye] = useState(true);

  const onSubmit = (data) => {
    localStorage.setItem("colorschema", "orange");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fixed");
    localStorage.setItem("layoutpos", "fluid");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "dark");
    mutation.mutate({
      email: data.email,
      password: data.password,
    });
  };

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to="/">
                  <img src={headerlogo} alt="XpertPrime" />
                </Link>
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">Access to our dashboard</p>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${errors?.email ? "error-input" : ""}`}
                              type="text"
                              {...field}
                              autoComplete="true"
                            />
                          )}
                        />
                        <span className="text-danger">{errors.email?.message}</span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to={`/forgot-password?email=${encodeURIComponent(watchEmail)}`}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${errors?.password ? "error-input" : ""}`}
                                type={eye ? "password" : "text"}
                                {...field}
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                          />
                        </div>
                        <span className="text-danger">{errors.password?.message}</span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </div>
  );
};
export default Login;
