import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Applogo, Avatar_09, uploadIcon, logo3 } from "../../../../Routes/ImagePath/index";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchThemeSettings,
  updateThemeSettings,
} from "../../../../hooks/useSettings";
import GlobalLoader from "../../../layout/GlobalLoader";
import InfoToast from "../../../layout/InfoToast";

const ThemeSettings = () => {
  const [avatarURL, setAvatarURL] = useState(logo3);
  const [uploadedFavicon, setUploadedFavicon] =useState(null)
  const [uploadedLogo, setUploadedLogo] =useState(null)
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState({ label: "", type: "" });
  const [formData, setFormData] = useState({
    site_name: "",
    currency_symbol: "",
    currency_code: "",
    logo: null,
    favicon: null,
  });
  const [formDataObj, setFormDataObj] = useState(null);

  const fileUploadRef = useRef();
  const faviconUploadRef = useRef();

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  }
  const handleFaviconUpload = (event) => {
    event.preventDefault();
    faviconUploadRef.current.click();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const uploadImageDisplay = async () => {
    try {
      setAvatarURL(uploadIcon);
      const uploadedFile = fileUploadRef.current.files[0];
      setUploadedLogo(uploadedFile)
    } catch (error) {
      console.error(error);
      setAvatarURL(logo3);
    }
  }
  const uploadFaviconDisplay = async () => {
    try {
      setAvatarURL(uploadIcon);
      const uploadedFile = faviconUploadRef.current.files[0];
      setUploadedFavicon(uploadedFile)
    } catch (error) {
      console.error(error);
      setAvatarURL(logo3);
    }
  }


  useEffect(() => {
    const formDataObj = new FormData();
    (uploadedFavicon !==null && formDataObj.append("favicon",uploadedFavicon));
    (uploadedLogo !==null && formDataObj.append("logo",uploadedLogo));
    console.log(uploadedFavicon,"favicon in useEffect")
    console.log(uploadedLogo,"logo in useEffect")
    formDataObj.append("site_name", formData.site_name);
    formDataObj.append("currency_code",formData.currency_code);
    formDataObj.append("currency_symbol",formData.currency_symbol);
    setFormDataObj(formDataObj)
  }, [uploadedFavicon, formData, uploadedLogo])
  


  useEffect(() => {
    const timeoutId = setTimeout(() => setSuccess(false), 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => setSuccess(false);

// ====================================Fetch Settings Data--===============================
  const { data, error, status, refetch } = useQuery({
    queryKey: ["theme-settings"],
    queryFn: fetchThemeSettings,
    refetchOnWindowFocus: false,
  });

// ====================================Update Settings Data--===============================

  useEffect(() => {
    if (data) {
      setFormData({
        site_name: data.site_name,
        currency_symbol: data.currency_symbol,
        currency_code: data.currency_code,
        logo: data.logo || null,
        favicon: data.favicon || null,
      });
    }
  }, [data]);


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch("https://phpstack-1280463-4782840.cloudwaysapps.com/api/settings-theme", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formDataObj
      });
  
      if (response.ok) {
        const data = await response.json();
        setAvatarURL(data?.location);
        setSuccess(true);
        setToastType({ label: "Theme Settings Updated", type: "Updated" });
        refetch();
      } else {
        setToastType({
          label: "An Error Occurred While Updating Theme Settings",
          type: "Error",
        });
        setSuccess(true);
        refetch();
      }
    } catch (error) {
      console.error(error);
      setAvatarURL(logo3);
    }
  };
  

  useEffect(() => {
    localStorage.setItem("logo", formData.logo);
    localStorage.setItem("favicon", formData.favicon);
  }, [formData.logo, formData.favicon])

  if (status === "loading" || isRefetching) {
    return <GlobalLoader />;
  };


  

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              <Breadcrumbs maintitle="Theme Settings" />
              <form onSubmit={handleFormSubmit}>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Website Name</label>
                  <div className="col-lg-9">
                    <input
                      name="site_name"
                      className="form-control"
                      value={formData.site_name}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Currency Symbol</label>
                  <div className="col-lg-9">
                    <input
                      name="currency_symbol"
                      className="form-control"
                      value={formData.currency_symbol}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Currency Code</label>
                  <div className="col-lg-9">
                    <input
                      name="currency_code"
                      className="form-control"
                      value={formData.currency_code}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                {/* <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Light Logo</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      name="logo"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    <span className="form-text text-muted">
                      Recommended image size is 40px x 40px
                    </span>
                  </div>
                  <div className="col-lg-2">
                    {formData.logo && (
                      <div className="img-thumbnail float-end">
                        <img
                          src={
                            formData.favicon instanceof File
                              ? URL.createObjectURL(formData.favicon)
                              : Applogo
                          }
                          alt="Logo"
                          width={40}
                          height={40}
                        />
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Light Logo</label>
                  <div className="col-lg-7">
                    <button
                      type='submit'
                      onClick={handleImageUpload}
                      hidden>
                    </button>
                    <input
                      type="file"
                      id="file"
                      ref={fileUploadRef}
                      className="form-control"
                      onChange={uploadImageDisplay}
                   />
                    <span className="form-text text-muted">
                      Recommended image size is 40px x 40px
                    </span>
                  </div>
                  <div className="col-lg-2  float-end">
                    <img
                      src={formData?.logo}
                      alt="Avatar" 
                      width={45}
                      height={45}
                      />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Favicon</label>
                  <div className="col-lg-7">
                    <button
                      type='submit'
                      onClick={handleFaviconUpload}
                      hidden>
                    </button>
                    <input
                      type="file"
                      id="favicon"
                      ref={faviconUploadRef}
                      className="form-control"
                      onChange={uploadFaviconDisplay}
                   />
                    <span className="form-text text-muted">
                      Recommended image size is 40px x 40px
                    </span>
                  </div>
                  <div className="col-lg-2 float-end">
                    <img
                      src={formData?.favicon}
                      alt="Avatar" 
                      width={45}
                      height={45}
                      />
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default ThemeSettings;
