import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = (props) => {
  const location = useLocation();
  const [active, setActive] = useState('b1');

  const handleButtonClick = (buttonId) => {
    setActive(buttonId);
    localStorage.setItem('activeButton', buttonId);
  };
  useEffect(() => {
    const savedActiveButton = localStorage.getItem('activeButton');
    if (savedActiveButton) {
      setActive(savedActiveButton);
    }
  }, []);

  const handleExport = async () => {
    const queryParams = new URLSearchParams(props.initialFilters).toString();
    const exportUrl = `https://txphrms.xpertprime.com/api/attendance-export?${queryParams}`;

    try {
      const response = await fetch(exportUrl, {
        method: "GET",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert response to a blob and trigger file download
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "attendance-export.xlsx"; // Adjust the filename as needed
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error("Error exporting attendance:", error);
    }
  };

  let addButton = null;
  if (
    location.pathname === "/form-basic-inputs" ||
    location.pathname === "/form-input-groups" ||
    location.pathname === "/form-horizontal" ||
    location.pathname === "/form-vertical" ||
    location.pathname === "/form-mask" ||
    location.pathname === "/form-validation" ||
    location.pathname === "/tables-basic" ||
    location.pathname === "/data-tables" ||
    location.pathname === "/performance" ||
    location.pathname === "/payments" ||
    location.pathname === "/ui/components" ||
    // location.pathname === "/admin-attendance" ||
    // location.pathname === "/attendance-employee" ||
    location.pathname === "/task-board" ||
    location.pathname === "/leads" ||
    location.pathname === "/create-estimate" ||
    location.pathname === "/edit-estimate" ||
    location.pathname === "/create-invoice" ||
    location.pathname === "/edit-invoice" ||
    location.pathname === "/payments" ||
    location.pathname === "/payroll-items" ||
    location.pathname === "/activities" ||
    location.pathname === "/expense-reports" ||
    location.pathname === "/invoice-reports" ||
    location.pathname === "/payments-reports" ||
    location.pathname === "/task-reports" ||
    location.pathname === "/user-reports" ||
    location.pathname === "/payslip-reports" ||
    location.pathname === "/attendance-reports" ||
    location.pathname === "/user-dashboard" ||
    location.pathname === "/user-all-jobs" ||
    location.pathname === "/saved-jobs" ||
    location.pathname === "/interviewing" ||
    location.pathname === "/offered-jobs" ||
    location.pathname === "/visited-jobs" ||
    location.pathname === "/knowledgebase" ||
    location.pathname === "/questions" ||
    location.pathname === "/jobs-dashboard" ||
    location.pathname === "/manage-resumes" ||
    location.pathname === "/shortlist-candidates" ||
    location.pathname === "/offer_approvals" ||
    location.pathname === "/schedule-timing" ||
    location.pathname === "/apptitude-result" ||
    location.pathname === "/job-aptitude" ||
    location.pathname === "/applied-jobs" ||
    location.pathname === "/offered-jobs" ||
    location.pathname === "/archived-jobs" ||
    location.pathname === "/subscriptions-company" ||
    location.pathname === "/subscribed-companies" ||
    location.pathname === "/profile" ||
    location.pathname === "/job-list" ||
    location.pathname === "/job-view" ||
    location.pathname === "/search" ||
    location.pathname === "/faq" ||
    location.pathname === "/blank-page" ||
    location.pathname === "/user-asset-details" ||
    location.pathname === "/leaves-setting" ||
    location.pathname === "/project-reports" ||
    location.pathname === "/client-profile" ||
    location.pathname === "/leave-settings"
  ) {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
             {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
      </div>
    );
  } else if (location.pathname === "/admin-attendance") {
    addButton = (
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-8">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-1">
          <button className="btn btn-primary px-4 py-2" onClick={handleExport}>
            Export
          </button>
        </div>
        <div className="col-3">
          <div className="container bg-white rounded px-3 py-2">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <i className="fa-regular fa-check-circle text-success me-2"></i>
                <span>Present</span>
              </div>
              <div className="col-6 d-flex align-items-center">
                <i className="fa-regular fa-times-circle text-danger me-2"></i>
                <span>Absent</span>
              </div>
              <div className="col-6 d-flex align-items-center">
                <i className="fa fa-sign-out-alt text-primary me-2"></i>
                <span>Leave</span>
              </div>
              <div className="col-6 d-flex align-items-center">
                <i className="fa-regular fa-sun text-warning me-2"></i>
                <span>Holiday</span>
              </div>
              <div className="col-6 d-flex align-items-center">
                <i className="fas fa-adjust text-purple me-2"></i>
                <span>Halfday</span>
              </div>
              <div className="col-6 d-flex align-items-center">
                <i className="fa-regular fa-calendar-alt text-info me-2"></i>
                <span>Weekend</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    location.pathname === "/company-settings" ||
    location.pathname === "/attendance-request" ||
    location.pathname === "/settings/localization" ||
    location.pathname === "/attendance-settings" ||
    location.pathname === "/theme-settings" ||
    location.pathname === "/settings/roles-permissions" ||
    location.pathname === "/settings/approval-setting" ||
    location.pathname === "/settings/invoice-settings" ||
    location.pathname === "/settings/salary-settings" ||
    location.pathname === "/settings/notifications-settings" ||
    location.pathname === "/settings/toxbox-setting" ||
    location.pathname === "/settings/cron-setting"
  ) {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">{props.title}</li>
          </ul>
        </div>
      </div>
    );
  } else if (location.pathname === "/admin-dashboard") {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">{props.title}</li>
          </ul>
        </div>
      </div>
    );
  } else if (location.pathname === "/invoice-view") {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <div className="btn-group btn-group-sm">
            <button className="btn btn-white">CSV</button>
            <button className="btn btn-white">PDF</button>
            <button className="btn btn-white">
              <i className="fa-solid fa-print fa-lg" /> Print
            </button>
          </div>
        </div>
      </div>
    );
  } else if (
    location.pathname === "/projects" ||
    location.pathname === "/clients" ||
    location.pathname === "/clients-list" ||
    location.pathname === "/project-list" ||
    location.pathname === "/employees" ||
    location.pathname === "/employees-list"
  ) {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
             {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <Link
            to="#"
            className="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target={props.modal}
          >
            <i className="fa fa-plus" /> {props.name}
          </Link>
          <div className="view-icons">
            <Link
              to={props.Linkname}
              className={`grid-view btn btn-link ${active === 'b1' ? 'active' : ''} mx-2`}
              onClick={() => handleButtonClick('b1')}
            >
              <i className="fa fa-th" />
            </Link>
            <Link
              to={props.Linkname1}
              className={`list-view btn ${active === 'b2' ? 'active' : ''} btn-link`}
              onClick={() => handleButtonClick('b2')}
            >
              <i className="fa fa-bars" />
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (
    location.pathname === "/estimates" ||
    location.pathname === "/invoices"
  ) {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <Link
            to={props.link}
            className="btn add-btn"
            data-bs-target={props.modal}
          >
            <i className="fa fa-plus" />
            {props.name}
          </Link>
        </div>
      </div>
    );
  } else if (
    location.pathname === "/employee-reports" ||
    location.pathname === "/leave-reports" ||
    location.pathname === "/daily-reports"
  ) {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto">
          <Link to="#" className="btn btn-primary">
            PDF
          </Link>
        </div>
      </div>
    );
  } else if (location.pathname === "/project-view") {
    addButton = (
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">{props.maintitle}</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
               {props.title}
              </li>
              <li className="breadcrumb-item active">{props.subtitle}</li>
            </ul>
          </div>
          <div className="col-auto float-end ms-auto">
            <Link
              to="#"
              className="btn add-btn"
              data-bs-toggle="modal"
              data-bs-target="#edit_project"
            >
              <i className="fa-solid fa-plus"></i> {props.add}
            </Link>
            <Link
              to={props.menu}
              className="btn btn-white float-end me-3"
              data-bs-toggle="tooltip"
              title="Task Board"
            >
              <i className="fa-solid fa-bars"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (location.pathname === "/salary-view") {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{props.title}</Link>
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <div className="btn-group btn-group-sm">
            <button className="btn btn-white">CSV</button>
            <button className="btn btn-white">PDF</button>
            <button className="btn btn-white">
              <i className="fa fa-print fa-lg" /> Print
            </button>
          </div>
        </div>
      </div>
    );
  } else if (location.pathname === "/interview-questions") {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{props.title}</Link>
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <Link
            to="#"
            className="btn add-btn "
            data-bs-toggle="modal"
            data-bs-target={props.modalone}
          >
            <i className="fa fa-plus" />
            {props.nameone}
          </Link>

          <Link
            to="#"
            className="btn add-btn me-2"
            data-bs-toggle="modal"
            data-bs-target={props.modaltwo}
          >
            <i className="fa fa-plus" />
            {props.nametwo}
          </Link>
        </div>
      </div>
    );
  } else {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <Link
            to="#"
            className="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target={props.modal}
          >
            <i className="fa fa-plus" />
            {props.name}
          </Link>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="page-header">{addButton}</div>
    </>
  );
};

export default Breadcrumbs;
