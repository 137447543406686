import React, { useState } from "react";
import { Applogo } from "../../../Routes/ImagePath";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { createNewPass } from "../../../hooks/useAuth";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const onEyeClick = () => setEye(prevEye => !prevEye);

  const [eye, setEye] = useState(true);
  const { mutate: createPassMutation } = useMutation({
    mutationFn: createNewPass,
    onSuccess: () => {
      navigate("/"); // Change to the desired route after successful password reset
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    createPassMutation({ email, password, password_confirmation: confirmPassword });
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="account-logo">
              <Link to="/admin-dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Change Password</h3>
                <form onSubmit={handleSubmit}>
                  <div className="input-block mb-3" style={{position:"relative"}}>
                    <label className="col-form-label">New password</label>
                    <input
                     type={eye ? "password" : "text"}
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      onClick={onEyeClick}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "60%", // Center the icon vertically
                        // transform: "translateY(-100%)", // Adjust for perfect centering
                        cursor: "pointer",
                      }}
                      className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    />
                  </div>
                  <div className="input-block mb-3" style={{position:"relative"}}>
                    <label className="col-form-label">Confirm password</label>
                    <input
                      type={eye ? "password" : "text"}
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                     <span
                      onClick={onEyeClick}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "60%", // Center the icon vertically
                        // transform: "translateY(-100%)", // Adjust for perfect centering
                        cursor: "pointer",
                      }}
                      className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    />
                  </div>
                  <div className="submit-section mb-4">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Update Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
